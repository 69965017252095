<template>
  <div class="container doc-container">
    <h2 class="h2_title">监督中心</h2>
    <h4>我们欢迎您的监督：</h4>
    <ol class="ml-2rem">
      <li><a class="link-item" href="javascript:void(0);" @click="onLinkBtnClick('/doc/doc1')">免责声明</a></li>
      <li><a class="link-item" href="javascript:void(0);" @click="onLinkBtnClick('/doc/doc2')">违规记录处罚规则与实施细则</a></li>
      <li><a class="link-item" href="javascript:void(0);" @click="onLinkBtnClick('/doc/doc3')">知识产权投诉指引</a></li>
    </ol>
    <h2 class="h2_title">关于我们</h2>
    <div class="row no-gutters">
      <div class="col">
        佰悦互娱 成立于2021年5月。<br />
        <br />
        公司大多数成员来自腾讯，网易，畅游等一线互联网。一群多年游戏行业从业者，10年以上硬核游戏玩家，让整个企业文化以休闲、简单、快乐为主调，以超越自己、不断挑战、全力以赴、No Game No Life
        的游戏精神为职业态度。<br />
        <br />
        公司拟大力发展海内外市场，以休闲游戏、小游戏自研自发为主，目前已有60+款小游戏，多款海外APP游戏同步运营。<br />
      </div>
    </div>
    <br />
    <h2 class="h2_title">其他疑问</h2>
    关于此网站有任何疑问请通过以下方式咨询我们：
    <ul class="ml-2rem">
      <li>电子邮箱：bytefun666@gmail.com</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SupervisionCenter',
  methods: {
    // 处理函数,当导航按项被点击时触发
    onLinkBtnClick(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style scoped lang="scss">
// @import '../styles/common.scss';
.doc-container {
  margin-top: 2rem !important;
  min-height: 72vh;
  // 链接项
  .link-item {
    color: #007bff;
    &:hover {
      text-decoration: underline;
    }
  }
  //   公共样式
  .ml-2rem {
    margin-left: 2rem;
  }
}
</style>
